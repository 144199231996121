import React, { useEffect, useRef } from 'react';
import { useSelector } from '@hooks/redux';
import LazySVG from '../LazySvg';
import AnimationSlide from '@components/animations/AnimationSlide';
import CloseIconModalRight from '@components/UI/CloseIconModalRight';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';

const animationSlideToTopDefault = {
  initialPosition: 'top',
  initialX: '0',
};

const animationDurationModal = 0.2;
const animationDurationSection = 0.2;
const animationYSection = '30px';

type HeaderMenuProps = {
  className?: string;
  show: boolean;
  closeModal: () => void;
  closeDataId?: string;
  header?: React.ReactNode;
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
  thirdColumn?: React.ReactNode;
  firstColumnProps?: any;
  secondColumnProps?: any;
  secondColumnClassName?: string;
  thirdColumnProps?: any;
};

const HeaderMenu = (props: HeaderMenuProps) => {
  const lang = useSelector(state => state.utils.lang);
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const firstButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    //Function to close the modal when pressing ESC
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        props.closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [props.closeModal]);

  useEffect(() => {
    const modalContainerRef = document.querySelector('.area-of-expertise__modalContainerRef');

    if (props.show && modalContainerRef) {
      //Function to find focusable elements
      const getFocusableElements = () => {
        return Array.from(
          modalContainerRef.querySelectorAll<HTMLElement>('button, a, input, textarea, select, details')
        ).filter(
          (el) =>
            !el.hasAttribute('disabled') && //Excludes disabled items
            el.getBoundingClientRect().width > 0 && //Excludes non-visible items
            el.getBoundingClientRect().height > 0 //Excludes non-visible items
        );
      };

      let focusableElements = getFocusableElements();

      //Initial focus on the first button (for example, the "X")
      if (firstButtonRef.current) {
        firstButtonRef.current.focus();
      } else {
        focusableElements[0]?.focus();
      }

      //Function to manage the cyclic Tab
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          const focusedIndex = focusableElements.indexOf(document.activeElement as HTMLElement);

          if (event.shiftKey) {
            // Shift + Tab -> Go to previous item
            if (focusedIndex === 0) {
              event.preventDefault();
              focusableElements[focusableElements.length - 1]?.focus();
            } else {
              focusableElements[focusedIndex - 1]?.focus();
            }
          } else {
            //Only Tab -> Go to next item
            if (focusedIndex === focusableElements.length - 1) {
              event.preventDefault();
              focusableElements[0]?.focus();
            } else {
              focusableElements[focusedIndex + 1]?.focus();
            }
          }
        }
      };

      //Add the listener for managing the Tab
      modalContainerRef.addEventListener('keydown', handleKeyDown);

      //MutationObserver to detect dynamic changes in modal
      const observer = new MutationObserver(() => {
        focusableElements = getFocusableElements();
      });

      //Configure the observer to detect node additions/removals
      observer.observe(modalContainerRef, {
        childList: true, //Observe direct additions/removals of children
        subtree: true, //Observe changes throughout the subtree
      });

      //Cleanup at unmount
      return () => {
        modalContainerRef.removeEventListener('keydown', handleKeyDown);
        observer.disconnect(); //Stop observing the changes
      };
    }
  }, [props.show, firstButtonRef]);

  return (
    <AnimatePresence>
      {props.show && (
        <FocusTrap
          active={props.show}
          focusTrapOptions={{
            allowOutsideClick: true,
          }}
        >
          <div className="area-of-expertise__modalContainerRef"
            ref={modalContainerRef}
            role='dialog'
            aria-modal='true'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}>
            <AnimationSlide
              {...animationSlideToTopDefault}
              initialY={animationYSection}
              duration={animationDurationSection}
              className={'header-menu__content ' + (props.className ? props.className : '')}
              extraProps={{ onClick: e => e.stopPropagation() }}
              exitProps={{ position: 'absolute' }}
            >
              {props.header && (
                <AnimationSlide
                  {...animationSlideToTopDefault}
                  initialY={animationYSection}
                  duration={animationDurationSection}
                  delay={animationDurationModal}
                  useSectionTagHtml={true}
                  {...props.firstColumnProps}
                >
                  {props.header}
                </AnimationSlide>
              )}
              <div className="area-of-expertise__container">
                <CloseIconModalRight
                  onClick={props.closeModal}
                  ariaLabel={lang?.CLOSE_MODAL}
                  dataElementId={props?.closeDataId}
                />
                <button
                  className={'button-no-back modal-right__close'}
                  aria-label={lang?.CLOSE_MODAL}
                  onClick={props.closeModal}
                  data-element-id={props?.closeDataId}
                  ref={firstButtonRef}
                >
                  <LazySVG src="/images/svg-icons/x-icon2.svg" alt={lang?.CLOSE_MODAL} />
                </button>
                <AnimationSlide
                  className="area-of-expertise__section scrollable-section area-of-expertise__first-column"
                  {...animationSlideToTopDefault}
                  initialY={animationYSection}
                  duration={animationDurationSection}
                  delay={animationDurationModal}
                  useSectionTagHtml={true}
                  {...props.firstColumnProps}
                >
                  {props.firstColumn}
                </AnimationSlide>
                <AnimationSlide
                  className={
                    'area-of-expertise__section area-of-expertise__second-column ' +
                    (!!props.thirdColumn ? '' : 'area-of-expertise__column-larger') +
                    (!props.secondColumnClassName ? '' : ' ' + props.secondColumnClassName)
                  }
                  {...animationSlideToTopDefault}
                  initialY={animationYSection}
                  duration={animationDurationSection}
                  delay={animationDurationModal + animationDurationSection}
                  useSectionTagHtml={true}
                  {...props.secondColumnProps}
                >
                  {props.secondColumn}
                </AnimationSlide>
                {props.thirdColumn && (
                  <AnimationSlide
                    className="area-of-expertise__section  area-of-expertise__section--no-padding area-of-expertise__third-section"
                    {...animationSlideToTopDefault}
                    initialY={animationYSection}
                    duration={animationDurationSection}
                    delay={animationDurationModal + animationDurationSection * 2}
                    useSectionTagHtml={true}
                    {...props.thirdColumnProps}
                  >
                    {props.thirdColumn}
                  </AnimationSlide>
                )}
              </div>
            </AnimationSlide>
          </div>
        </FocusTrap>
      )}
    </AnimatePresence>
  );
};

export default HeaderMenu;
