import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@redux-actions/';
import CloseIframe from '@svg-icons/close2.svg';
import { Course, CoursesMap, LangMap } from '@model/CoursesClass';
import {
  areAllActivitiesCompleted,
  getActivityById,
  getFollowingActivity,
  getFollowingActivityFromActivities,
  getNextActivity,
  getUnlockedActivities,
  handleOnEnterKeyPress,
  isActivityBlocked,
  isActivityCompleted,
  isAnEvent,
  isChildOfAnEvent,
  isCompletedCourse,
  isLivestreamCourse,
  isMultiActivity,
  isPodcast,
} from '@utility/Api';
var playerjs = require('player.js');
const axios = require('axios');
const ReactHlsPlayer = lazy(() => import('react-hls-player'));
import * as urlList from '../../config';
import FocusTrap from 'focus-trap-react';
import { useSelector } from '@hooks/redux';
import ArrowRight from '@svg-icons/right.svg';
import CircleProgress from './CircleProgress';
import MultiactivityCompletionPercentage from './MultiactivityCompletionPercentage';
import ModalEvolution from '@components/UI/ModalEvolution';
import ButtonV2 from './ButtonV2';
import { UserProfile } from '@model/User';
import { getLivestreamRecording } from '@utility/LivestreamUtility';
import { getCookie, setCookie } from '@utility/cookie';
import { COOKIE, EXPIRE_COOKIE_SAVING_STATE } from '@utility/const';
import Player from '@components/VideoTracking/Player';

type IframePopupProps = {
  lang: LangMap;
  url: string;
  urlChina?: string;
  urlChat?: string;
  urlToCheck: string;
  eventId: string;
  isVideo: boolean;
  course: Course;
  close: (isCompleted?: boolean) => void;
};
//Cina, Hong Kong, Korea del nord, Indonesia, Cuba, Iran
const differentUrlLivestreamCountries = ['CN', 'HK', 'KP', 'ID', 'CU', 'IR'];
const preventZoom = ', maximum-scale=1.0, user-scalable=0';
const getCountryCodeBasedOnLocation = async (mock = false) => {
  if (mock) {
    return Promise.resolve(true);
  }
  try {
    const url = 'https://api.userinfo.io/userinfos';
    const response = await axios.get(url);
    return !!(
      response?.data?.country?.code &&
      differentUrlLivestreamCountries.includes(response.data.country.code)
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

const IframePopup = ({
  lang,
  url,
  urlChina,
  urlChat,
  eventId,
  close,
  urlToCheck,
  isVideo,
  course,
}: IframePopupProps) => {
  
  const dispatch = useDispatch();
  const playerRef = React.useRef<HTMLVideoElement>();
  const [showSureModal, setShowSureModal] = useState(false);
  const [isUserInChina, setIsUserInChina] = useState(false);
  // const [creditsToReceive, setCreditsToReceive] = useState(0);
  const [isLiveStream, setIsLiveStream] = useState(false);
  const [userStartLive, setUserStartLive] = useState<Date>();
  const [urlCn, setUrlCn] = useState<string>();
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const activityIdIframe: string = useSelector(state => state.utils.activityIdIframe);
  const subtitleCodeIframe: string = useSelector(state => state.utils.subtitleCodeIframe);
  const autoManageHistoryIframeOnClose: boolean = useSelector(state => state.utils.autoManageHistoryIframeOnClose);
  const [courseUpdated, setCourseUpdated] = useState<Course>(null);
  const [showProgressSavedModal, setShowProgressSavedModal] = useState<boolean>(false);
  const [showActivityCompletedModal, setShowActivityCompletedModal] = useState<boolean>(false);
  const [canGoNextActivity, setCanGoNextActivity] = useState<boolean>(false);
  const [isMultiactivityCompleted, setIsMultiactivityCompleted] = useState<boolean>(false);
  const [isPercentageEnough, setIsPercentageEnough] = useState<boolean>(false);
  const [timeoutPlayer, setTimeoutPlayer] = useState<any>(null);
  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  const [historyLength, setHistoryLength] = useState(window?.history.length);

  useEffect(() => {

    //avoid scroll when the modal is open
    const avoidScrollWhenModalIsOpen = () => {
      document.querySelector('html').classList.add('block-scroll');
      const metaViewport1: Element = document.querySelector("head meta[name='viewport']");
      let metaViewportContent1 = metaViewport1.getAttribute('content');
      metaViewport1.setAttribute('content', metaViewportContent1 + preventZoom);
    }

    // handle live stream
    const handleIsLiveStream = () => {
      const isLive = isLivestreamCourse(course);
      if (isLive) {
        getCountryCodeBasedOnLocation()
          .then(isInChina => {
            if (isInChina) {
              getUrlChina();
            }
            setIsUserInChina(isInChina);
          })
          .catch(err => {
            console.error(err);
            setIsUserInChina(false);
          });
        //save when user started the livestream
        setUserStartLive(new Date());
      }
      setIsLiveStream(isLive);
    }

    avoidScrollWhenModalIsOpen();
    handleIsLiveStream();
    (document.querySelector('.iframe-v3 .modal-close') as HTMLDivElement).focus();

    // cleanup
    return () => {

      // cleanup function to remove the block-scroll class
      const removeBlockScrollClass = () => {
        document.querySelector('html').classList.remove('block-scroll');
        const metaViewport2: Element = document.querySelector("head meta[name='viewport']");
        let metaViewportContent2 = metaViewport2.getAttribute('content')?.replace(preventZoom, '');
        metaViewport2.setAttribute('content', metaViewportContent2);
      }

      removeBlockScrollClass();
  
    };

  }, []);

  useEffect(() => {
    //url video demo https://test.player.crosscast-system.com/video/ffb65ab9-f980-46c0-b1ac-bc2686e2fced?api=1
    //console.log('TEST URL VIDEO : ', url);
    if (
      (!isVideo && !isPodcast(course) && course?.liveInfo[0]?.recordingUrls?.length === 0) ||
      !url.includes('crosscast-system.com')
    ) {
      setIsPercentageEnough(true);
    } 
  }, [activityIdIframe]);


  /**
   * Save a cookie for each course closed. Last 4 minutes using this format:
   * -    id  : coursesProgress_[courseId]
   * - value  : [courseId]
   * - expires: 4 * 60 * 1000 (4 minutes)
  */
  const saveProgressInCookie = () => {
    const idCookie = `${COOKIE.COURSES_PROGRESS}_${course?.courseId}`
    setCookie(idCookie, course?.courseId , new Date().getTime() + EXPIRE_COOKIE_SAVING_STATE) //expires 4 minutes
  }

  const handleCloseWrapper = () => {
    console.log('handleCloseWrapper');

    !isCompletedCourse(course) ? saveProgressInCookie() : "";

    handleClose();
    if(!autoManageHistoryIframeOnClose) return
    if (historyLength < window?.history.length) {
      setTimeout(() => {
        console.log('timeout');
        history.go(historyLength - window?.history.length);
      }, 750);
    }
  };

  const handleClose = () => {
    console.log('handleClose', isPercentageEnough);
    if (course && isLivestreamCourse(course) && course?.liveInfo[0]?.recordingUrls?.length === 0) {
      //get the seconds the user has watched the live
      const userDurationLive = getUserDurationLive();


      // bugfix: FIX removed sureModal commit: bc78773
      sendLivestreamCompletionRequest();
      close();
      
      // OLD
      // if the user was not enough within the livestream to get the completion --> show exit confirm modal
      // else close iframe
      /*if (isChildOfAnEvent(course.courseId, coursesMap)) {
        if (userDurationLive < course.liveInfo[0].noCompletion) {
          close();
        } else {
          sendLivestreamCompletionRequest();
          close();
        }
      } else {
        if (userDurationLive < course.liveInfo[0].noCompletion) {
          close();
        } else {
          sendLivestreamCompletionRequest();
          close();
        }
      }*/
    } else if (isMultiActivity(course) && courseUpdated) {
      const activity = getActivityById(courseUpdated.courseDetails, activityIdIframe);
      if (!isActivityCompleted(activity)) {
        setShowProgressSavedModal(false);
        close();
      } else {
        setShowActivityCompletedModal(false);
        close();
      }
    } else {
      close(isPercentageEnough);
    }
  };

  useEffect(() => {
    //Function to close the modal when pressing ESC
    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseWrapper();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [historyLength]);

  const goToFollowingActivity = e => {
    setIsPercentageEnough(false);
    const unlockedActivities = getUnlockedActivities(courseUpdated.courseDetails);
    const followingActivity =
      getFollowingActivityFromActivities(unlockedActivities, activityIdIframe) ||
      getNextActivity(courseUpdated, true, false, activityIdIframe);
    if (followingActivity) {
      //get subtitle set for the current activity
      dispatch(
        actions.openActivity(
          followingActivity,
          courseUpdated,
          subtitleCodeIframe ? { code: subtitleCodeIframe } : null
        )
      );
    }
    if (showActivityCompletedModal) {
      setShowActivityCompletedModal(false);
    }

    const target = e.target;
    if (e)
      setTimeout(_ => {
        // debugger
        target.blur();
      }, 500);
  };

  useEffect(() => {
    let courseUpdatedTemp = null;
    let isMultiactivityCompletedTemp = false;

    if (course && coursesMap) {
      courseUpdatedTemp = coursesMap[course.courseIdMaster];
      if (isMultiActivity(courseUpdatedTemp)) {
        if (
          isCompletedCourse(courseUpdatedTemp) ||
          areAllActivitiesCompleted(courseUpdatedTemp.courseDetails)
        ) {
          isMultiactivityCompletedTemp = true;
        }
      }
    }
    setCourseUpdated(courseUpdatedTemp);
    setIsMultiactivityCompleted(isMultiactivityCompletedTemp);
  }, [course, coursesMap, activityIdIframe]);

  useEffect(() => {
    console.log('isPErc0', isPercentageEnough);
    let canGoNextActivityTemp = false;
    if (courseUpdated && courseUpdated.courseDetails) {
      if (isMultiActivity(courseUpdated)) {
        const currentActivity = getActivityById(courseUpdated.courseDetails, activityIdIframe);
        if ((isPercentageEnough && isVideo) || isActivityCompleted(currentActivity)) {
          const unlockedActivities = getUnlockedActivities(courseUpdated.courseDetails);
          const followingActivity =
            getFollowingActivityFromActivities(unlockedActivities, activityIdIframe) ||
            getNextActivity(courseUpdated, true, false, activityIdIframe);
          canGoNextActivityTemp =
            !!followingActivity &&
            (!isVideo || isPercentageEnough || isActivityCompleted(currentActivity));
          console.log('canGoNextActivityTemp', canGoNextActivityTemp);
        }
      }
    }
    console.log('canGoNextActivityTemp', canGoNextActivityTemp, {
      courseUpdated,
      activityIdIframe,
      isPercentageEnough,
    });
    setCanGoNextActivity(canGoNextActivityTemp);
  }, [courseUpdated, activityIdIframe, isPercentageEnough]);

  const getUrlChina = () => {
    if (!urlChina) {
      setUrlCn(urlList.LIVESTREAM_DEFAULT_IFRAME_CHINA.replace('{eventId}', eventId));
    } else {
      setUrlCn(urlChina);
    }
  };
  const sendLivestreamCompletionRequest = (duration: number = null) => {
    if (duration === null) {
      duration = getUserDurationLive();
    }
    dispatch(actions.sendLivestreamCompletion(course, duration));
    close();
  };
  const getUserDurationLive = () => {
    //get the seconds the user has watched the live
    const userEndLive = new Date();
    return Math.round((userEndLive.getTime() - userStartLive.getTime()) / 1000);
  };

  useEffect(() => {
    if (!isUserInChina) {
      let iframe = document.getElementById('iframe-video') as HTMLIFrameElement;
      if (iframe) {
        var player = new playerjs.Player(iframe);
        window.onorientationchange = function () {
          if (Math.abs(+window.orientation) === 90) {
            player.send({ method: 'fullscreenenter' });
          } else {
            player.send({ method: 'fullscreenexit' });
          }
        };
      }
    }
    return () => (window.onorientationchange = null);
  }, [isUserInChina]);

  return (
    <FocusTrap>
      <div className={'iframe-v3' + (isVideo ? ' iframe-video' : '')}>
        <header className="iframe-v3__header">
          <button className={'modal-close focus-outline'} onClick={handleCloseWrapper} aria-label={lang.EXIT}>
            <span className="exit">{lang.EXIT}</span>
            <CloseIframe />
          </button>
          {canGoNextActivity && (
            <ButtonV2
              variant="primary"
              small
              className="iframe-v3__header-next-activity"
              handleClick={goToFollowingActivity}
            >
              {lang.NEXT_ACTIVITY}
              <ArrowRight />
            </ButtonV2>
          )}
        </header>
        {isLiveStream && course?.liveInfo[0]?.recordingUrls?.length === 0 && (
          <div className="container-iframe-livestream">
            {console.log('case:livestream')}
            {isUserInChina && urlCn ? (
              <div className="iframe-livestream-wrapper">
                <Suspense fallback={null}>
                  <ReactHlsPlayer src={urlCn} playerRef={playerRef} controls />
                </Suspense>
              </div>
            ) : (
              <div className="iframe-livestream-wrapper">
                {/* dangerouslySetInnerHTML={printHTMLIframe(url)} */}
                <iframe
                  src={url}
                  width="100%"
                  height="100%"
                  frameBorder={0}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  tabIndex={0}
                    aria-label={course.courseFullName + " video"}
                />
              </div>
            )}
            {!isUserInChina && urlChat && (
              <div id="chat-div">
                {/* dangerouslySetInnerHTML={printHTMLIframe(urlChat)}> */}
                <iframe src={urlChat} width="100%" height="100%" frameBorder={0} tabIndex={0} aria-label={course.courseFullName + " video"} />
              </div>
            )}
          </div>
        )}
        {/* CASE VIDEO LESSON OR VC RECORDING */}
        {isVideo && !isLiveStream && (
          <>
            {/* PLAYER COMPONENT for video tracking */}
            <Player course={course} urlToCheck={urlToCheck} />
            {/* IFRAME to show video lesson or VC recording */}
            <iframe 
            	src={url} 
            	id="iframe-video" 
            	allowFullScreen 
            	tabIndex={0} 
            	aria-label={course.courseFullName + " video"}
        	/>
            {/* IFRAME to send completion after 80% */}
            {/* MOVED DIRECTLY INSIDE THE <Player /> ABOVE */}
            {/* {urlToCheck && isPercentageEnough && (
              <iframe
                src={urlToCheck}
                allowFullScreen
                id="iframe-video"
                style={{ display: 'none' }}
                tabIndex={0}
                aria-label={course.courseFullName + " video"}
              />
            )} */}
            {/* VIMEO SCRIPT */}
            {url.includes('vimeo') && (
              <script src="https://player.vimeo.com/api/player.js" />
            )}
          </>
        )}
        {!isVideo && isLiveStream && course?.liveInfo[0]?.recordingUrls?.length > 0 && (
          <>
            {console.log('case:livestream recording')}
            <iframe src={url} id="iframe-video" allowFullScreen tabIndex={0} aria-label={course.courseFullName + " video"}></iframe>
            {/* PLAYER COMPONENT for video tracking */}
            <Player course={course} urlToCheck={urlToCheck} />
          </>
        )}
        {!isVideo && !isLiveStream && (
          <div className="iframe-container">
            {console.log('case:other lesson')}
            <iframe src={url} allowFullScreen tabIndex={0} key={url} aria-label={course.courseFullName + " video"} />
            {console.log('history after iframe', window?.history)};
          </div>
        )}

        <ModalEvolution
          close={() => setShowSureModal(false)}
          show={showSureModal}
          hideX={true}
          modalClassName="progress-modal"
          icon="/images/svg-icons/circle-alert.svg"
          description={
            isLivestreamCourse(course) && course?.liveInfo[0]?.recordingUrls?.length === 0
              ? lang.SURE_LEAVE_NO_COMPLETION
              : lang.SURE_LEAVE_COURSE
          }
          mainButtonLabel={lang.STAY}
          mainButtonAction={() => setShowSureModal(false)}
          secondaryButtonLabel={lang.LEAVE}
          secondaryButtonAction={() => sendLivestreamCompletionRequest()}
        />

        <ProgressSavedModal
          lang={lang}
          show={showProgressSavedModal}
          close={() => setShowProgressSavedModal(false)}
          exit={close}
        />

        <ActivityCompletedModal
          lang={lang}
          show={showActivityCompletedModal}
          close={() => setShowActivityCompletedModal(false)}
          cta={goToFollowingActivity}
          exit={close}
          isCompleted={isMultiactivityCompleted}
          completedActivities={courseUpdated?.numActivities?.completedActivities}
          totalActivities={courseUpdated?.numActivities?.totalActivities}
          canGoNextActivity={canGoNextActivity}
        />
      </div>
    </FocusTrap>
  );
};
const ProgressSavedModal = ({ lang, show, close, exit }) => {
  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      modalClassName="progress-modal"
      title={lang.PROGRESS_SAVED_MODAL_TITLE}
      description={lang.PROGRESS_SAVED_MODAL_DESCR}
      mainButtonLabel={lang?.RESUME_ACTIVITY}
      mainButtonAction={close}
      secondaryButtonLabel={lang?.EXIT}
      secondaryButtonAction={exit}
    />
  );
};
const ActivityCompletedModal = ({
  lang,
  show,
  close,
  cta,
  exit,
  isCompleted,
  completedActivities,
  totalActivities,
  canGoNextActivity,
}) => {
  type ModalInfoType = {
    title: string;
    mainButtonLabel: string;
    mainButtonAction: () => void;
    secondaryButtonLabel?: string;
    secondaryButtonAction?: () => void;
  };

  const [modalInfo, setModalInfo] = useState<ModalInfoType>(null);

  useEffect(() => {
    if (isCompleted || !canGoNextActivity) {
      setModalInfo({
        title: isCompleted
          ? lang.COURSE_COMPLETED_MODAL_TITLE
          : lang.ACTIVITY_COMPLETED_MODAL_TITLE,
        mainButtonLabel: lang.EXIT,
        mainButtonAction: exit,
      });
    } else {
      setModalInfo({
        title: isCompleted
          ? lang.COURSE_COMPLETED_MODAL_TITLE
          : lang.ACTIVITY_COMPLETED_MODAL_TITLE,
        mainButtonLabel: lang.GO_NEXT_ACTIVITY,
        mainButtonAction: cta,
        secondaryButtonLabel: lang.EXIT,
        secondaryButtonAction: exit,
      });
    }
  }, [isCompleted, canGoNextActivity, lang, cta, exit]);

  return (
    <ModalEvolution
      close={() => {
        close();
        exit();
      }}
      show={show}
      hideX={true}
      modalClassName="progress-modal"
      headerElement={
        <div className="progress-modal__progress">
          <CircleProgress
            classNameFirstRing="circle-progress-activity"
            firstPercentageValue={
              totalActivities > 0 ? (completedActivities / totalActivities) * 100 : 0
            }
          />
          <MultiactivityCompletionPercentage
            completedActivities={completedActivities}
            totalActivities={totalActivities}
            isCompleted={isCompleted}
          />
        </div>
      }
      title={modalInfo?.title}
      mainButtonLabel={modalInfo?.mainButtonLabel}
      mainButtonAction={modalInfo?.mainButtonAction}
      secondaryButtonLabel={modalInfo?.secondaryButtonLabel}
      secondaryButtonAction={modalInfo?.secondaryButtonAction}
    />
  );
};
export default IframePopup;
